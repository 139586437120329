/**
 * api接口统一管理
 */
// import axios from './http.js'
import orther from './orther/index.js'
import index from './m/index.js'
import special from './m/special.js' // 专题
import my from './m/my.js' // 我的 = 个人中心
// import manager from './user/manager.js'
import integral from './m/integral.js'//积分
import article from './m/article.js'//文章
import common from './common/common.js'
const api = {
  orther,
  common,
  index,
  special,
  my,
  integral,
  article
}
export default api
