/**
 * meta中 role       为角色权限，决定用户是否有权限访问该路由
 * keepAlive        为缓存选项，如果该路由需要缓存，值设为true
 * requiresAuth     验证登录权限
 * title            页面标题
 */
 import Nofound from ".././components/page/Nofound.vue";
 import Iframe from ".././components/page/Iframe.vue";
 
 import Index from "@/views/index.vue"; // 首页
 import articleShare from "@/share/articleDetail.vue"; // 分享文章详情
 import specialShare from "@/share/specialDetail.vue"; // 分享专题详情
 import taskShare from "@/share/taskDetail.vue"; // 分享发声任务详情
 import score from "@/views/score.vue"; // 积分规则
 import myRenWu from "@/views/myRenWu.vue"; // 积分规则
 import qzInfo from "@/views/QzInfo.vue"; // 积分规则

export default [
  {
    // 问卷调查
    path: "/investigation",
    name: "/investigation",
    component: () => import('@/views/orther/investigation.vue'),
    meta: {
      requiresAuth: false,
      share_config: false
    },
  },
  {
    path: "/",
    component: Iframe,
    meta: {
      requiresAuth: false,
      share_config: true
    },
    children: [
         // 分享专题详情
         {
          path: "/qzInfo",
          name: "qzInfo",
          component: qzInfo,
          meta: {
            requiresAuth: false,
            title: "圈子详情",
            role: 0,
            keepAlive: false,
            share_config: false,
          },
        },
      // 我的任务
      {
        path: "/myRenWu",
        name: "/myRenWu",
        component: myRenWu,
        meta: {
          requiresAuth: false,
          title: "我的任务",
          role: 0,
          keepAlive: false,
          share_config: true,
        },
      },
      // 积分规则
      {
        path: "/score",
        name: "/score",
        component: score,
        meta: {
          requiresAuth: false,
          title: "积分规则",
          role: 0,
          keepAlive: false,
          share_config: true,
        },
      },
      // 分享文章详情
      {
        path: "/articleShare",
        name: "/articleShare",
        component: articleShare,
        meta: {
          requiresAuth: false,
          title: "我的运城",
          role: 0,
          keepAlive: false,
          share_config: false,
        },
      },
      // 分享专题详情
      {
        path: "/specialShare",
        name: "/specialShare",
        component: specialShare,
        meta: {
          requiresAuth: false,
          title: "我的运城",
          role: 0,
          keepAlive: false,
          share_config: false,
        },
      },
      // 分享发声任务详情
      {
        path: "/taskShare",
        name: "/taskShare",
        component: taskShare,
        meta: {
          requiresAuth: false,
          title: "我的运城",
          role: 0,
          keepAlive: false,
          share_config: false,
        },
      },
      // 首页
      {
        path: "/",
        name: "home",
        component: Index,
        meta: {
          requiresAuth: false,
          title: "我的运城",
          role: 0,
          keepAlive: true,
          share_config: true,
          active: '1'
        },
      },
      // 排行
      {
        path: '/rank',
        name: 'rank',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "index" */ '../views/rank.vue'),
        meta: {
          requiresAuth: false,
          title: "排行榜",
          role: 0,
          keepAlive: true,
          share_config: true,
          active: '2'
        }
      },
      // 专题
      {
        path: '/special',
        name: 'special',
        component: () => import(/* webpackChunkName: "index" */ '../views/special.vue'),
        meta: {
          requiresAuth: false,
          title: "专题",
          role: 0,
          keepAlive: true,
          share_config: false,
          active: '4'
        }
      },
      // 专题列表
      {
        path: '/specialList',
        name: 'specialList',
        component: () => import(/* webpackChunkName: "index" */ '../views/specialList.vue'),
        meta: {
          requiresAuth: false,
          title: "专题",
          role: 0,
          keepAlive: false,
          share_config: true,
          active: '4'
        }
      },
      // 视界列表
      {
        path: '/worldlList',
        name: 'worldlList',
        component: () => import(/* webpackChunkName: "index" */ '../views/world.vue'),
        meta: {
          requiresAuth: false,
          title: "视界",
          role: 0,
          keepAlive: false,
          share_config: false,
          active: '3'
        }
      },
      // 专题 详情
      {
        path: '/specialDetail',
        name: 'specialDetail',
        component: () => import(/* webpackChunkName: "index" */ '../views/specialDetail.vue'),
        meta: {
          requiresAuth: false,
          title: "专题",
          role: 0,
          keepAlive: false,
        }
      },
      // 任务list
      {
        path: '/renwuList',
        name: 'renwuList',
        component: () => import(/* webpackChunkName: "index" */ '../views/renwuList.vue'),
        meta: {
          requiresAuth: false,
          title: "任务",
          role: 0,
          keepAlive: true,
          share_config: true,
          active: '3'
        }
      },
      // 文章
      {
        path: '/article',
        name: 'article',
        component: () => import(/* webpackChunkName: "index" */ '../views/article.vue'),
        meta: {
          requiresAuth: false,
          title: "文章",
          role: 0,
          keepAlive: true,
          share_config: true,
          active: '3'
        }
      },
      // 文章详情
      {
        path: '/articleDetail',
        name: 'articleDetail',
        component: () => import(/* webpackChunkName: "index" */ '../views/articleDetail.vue'),
        meta: {
          requiresAuth: false,
          title: "文章详情",
          role: 0,
          keepAlive: false,
          active: '3'
        }
      },
      // 我的
      {
        path: '/my',
        name: 'my',
        component: () => import(/* webpackChunkName: "index" */ '../views/my.vue'),
        meta: {
          requiresAuth: false,
          title: "我的",
          role: 0,
          keepAlive: true,
          share_config: false,
          active: '5'
        }
      },
      // 我的文章 详情
      {
        path: '/myDetail',
        name: 'myDetail',
        component: () => import(/* webpackChunkName: "index" */ '../views/myDetail.vue'),
        meta: {
          requiresAuth: false,
          title: "我的",
          role: 0,
          keepAlive: false,
          active: '5'
        }
      },
      // 发布文章
      {
        path: '/articlePub',
        name: 'articlePub',
        component: () => import('../views/articlePub.vue'),
        meta: {
          requiresAuth: false,
          title: "发布文章",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 任务列表
      {
        path: '/taskList',
        name: 'taskList',
        component: () => import('../views/taskList.vue'),
        meta: {
          requiresAuth: false,
          title: "任务列表",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 区域排行
      {
        path: '/analysis',
        name: 'analysis',
        component: () => import('../views/analysis.vue'),
        meta: {
          requiresAuth: false,
          title: "区域排行",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 申请发声人
      {
        path: '/apply',
        name: 'apply',
        component: () => import('../views/apply.vue'),
        meta: {
          requiresAuth: false,
          title: "发声人申请",
          role: 0,
          keepAlive: false,
          share_config: true,
          active: '5'
        }
      },
      // 分享
      {
        path: '/share',
        name: 'share',
        component: () => import('../views/share.vue'),
        meta: {
          requiresAuth: false,
          title: "我的运城",
          role: 0,
          keepAlive: false,
          share_config: false,
        }
      },
      // 链路排行
      {
        path: '/linkRank',
        name: 'linkRank',
        component: () => import('../views/linkRank.vue'),
        meta: {
          requiresAuth: false,
          title: "链路排行",
          role: 0,
          keepAlive: false,
          share_config: false,
        }
      },
      // 积分商城
      {
        path: '/shopmall',
        name: 'shopmall',
        component: () => import('../views/integral/shopmall.vue'),
        meta: {
          requiresAuth: false,
          title: "积分商城",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 填写地址
      {
        path: '/address',
        name: 'address',
        component: () => import('../views/integral/address.vue'),
        meta: {
          requiresAuth: false,
          title: "填写地址",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 积分明细
      {
        path: '/detailed',
        name: 'detailed',
        component: () => import('../views/integral/detailed.vue'),
        meta: {
          requiresAuth: false,
          title: "积分明细",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 兑换记录
      {
        path: '/dui_record',
        name: 'dui_record',
        component: () => import('../views/integral/dui_record.vue'),
        meta: {
          requiresAuth: false,
          title: "兑换记录",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 订单详情
      {
        path: '/dui_detail',
        name: 'dui_detail',
        component: () => import('../views/integral/dui_detail.vue'),
        meta: {
          requiresAuth: false,
          title: "订单详情",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 积分商品详情
      {
        path: '/good_info',
        name: 'good_info',
        component: () => import('../views/integral/good_info.vue'),
        meta: {
          requiresAuth: false,
          title: "积分商品详情",
          role: 0,
          keepAlive: false,
          share_config: true,
        }
      },
      // 任务详情
      {
        path: '/taskDetail',
        name: 'taskDetail',
        component: () => import('../views/taskDetail.vue'),
        meta: {
          requiresAuth: false,
          title: "我的运城",
          role: 0,
          keepAlive: false,
          share_config: false,
          active: ''
        }
      },
      // 认证发生人
      {
        path: '/authentication',
        name: 'authentication',
        component: () => import('../views/authentication.vue'),
        meta: {
          requiresAuth: false,
          title: "认证发声人",
          role: 0,
          keepAlive: false,
          share_config: true,
          active: ''
        }
      },
      // 其它404
      {
        path: "/:pathMatch(.*)*",
        name: "noaccess",
        component: Nofound,
        meta: {
          requiresAuth: true,
          pageTitle: "404",
        },
      },
      {
        path: "/:pathMatch(.*)",
        name: "noaccess",
        component: Nofound,
        meta: {
          requiresAuth: true,
          pageTitle: "404",
        },
      }
    ]
  }
]
