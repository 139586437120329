/**
 * 首页 接口的统一出口
 */
import axios from '../http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块，把请求字段直接映射过来

const api = '/api/'

const index = {
  // 获取 专题分类
  getCate (params) {
    return axios.get(api + 'specialArticle/cate', { params: params })
  },
  // 获取 文章列表
  list (params) {
    return axios.get(api + 'specialArticle/list', { params: params })
  },
  // 获取 文章详情
  detail (params) {
    return axios.post(api + 'specialArticle/detail', qs.stringify(params))
  },
  // 获取 专题栏目
  subCate (params) {
    return axios.get(api + 'specialArticle/subCate', { params: params })
  },
}

// 导出接口
export default index
