/**
 * 首页 接口的统一出口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const api = "/api/";

const index = {
  // 视界
  shijie(params) {
    return axios.get(api + "specialArticle/shijie", { params: params });
  },
  // 首页 用户动态展示
  speakerLog(params) {
    return axios.get(api + "speaker/log", { params: params });
  },
  // 用户信息 - 点击我的，看去申请页面还是去我的页面
  memberInfo(params) {
    return axios.get(api + "speaker/memberInfo", { params: params });
  },
  // 获取微信分享 配置
  getShare(params) {
    return axios.get(api + "common/share", { params: params });
  },
  // 链路图 图表数据
  dutyMap(params) {
    return axios.get(api + "duty/map", { params: params });
  },
  // 数据分析 200条不分页
  rankCount(params) {
    return axios.post(api + "rank/count", qs.stringify(params));
  },
  // 发声任务链路排行
  dutyRank(params) {
    return axios.get(api + "rank/dutyRank", { params: params });
  },
  // 微信登录
  wxlogin(params) {
    return axios.post(api + "wxlogin", qs.stringify(params));
  },
  // 获取标签 地区+行业
  getTag(params) {
    return axios.get(api + "speaker/tag", { params: params });
  },
  // 获取标签 地区
  getArea(params) {
    return axios.get(api + "speaker/area", { params: params });
  },
  // 获取标签 行业
  getIndustry(params) {
    return axios.get(api + "speaker/industry", { params: params });
  },
  // 获取手机验证码
  getPhoneCode(params) {
    return axios.post(api + "phoneCode", qs.stringify(params));
  },
  // 获取图标数据
  getChart(params) {
    return axios.get(
      "https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples/data/asset/data/les-miserables.json",
      { params: params }
    );
  },
  // 图片上传
  upload(params) {
    return axios.post(api + "common/uploadBase64", qs.stringify(params));
  },

  // 发声任务列表
  dutyList(params) {
    return axios.get(api + "duty/list", { params: params });
  },
  // 发声任务详情
  dutyDetail(params) {
    return axios.get(api + "duty/detail", { params: params });
  },
  // 分享发声任务详情
  shareDetail(params) {
    return axios.get(api + "duty/shareDetail", { params: params });
  },
  // 我转发的任务详情
  myDetail(params) {
    return axios.get(api + "duty/myDetail", { params: params });
  },
  // 数据分析排行
  rankList(params) {
    return axios.get(api + "rank/list", { params: params });
  },
  // 县级区域列表
  areaOne(params) {
    return axios.get(api + "rank/areaOne", { params: params });
  },
  // 乡镇街道区域列表
  areaTwo(params) {
    return axios.post(api + "rank/areaTwo", qs.stringify(params));
  },
  // 地理位置修改
  speakerEditArea(params) {
    return axios.post(api + "speaker/editArea", qs.stringify(params));
  },
  // 获取认证标签列表
  getTags(params) {
    return axios.get(api + "applytag/getTags", { params: params });
  },
  // 申请认证详情
  getDetail(params) {
    return axios.get(api + "applytag/getDetail", { params: params });
  },
  // 申请发生人认证
  applytagCreat(params) {
    return axios.post(api + "applytag/creat", qs.stringify(params));
  },
  // 修改申请发生人认证
  applytagEdit(params) {
    return axios.post(api + "applytag/edit", qs.stringify(params));
  },
    // 圈子详情
    single_page(params) {
      return axios.get(api + "single_page/show", { params: params });
    },
};

// 导出接口
export default index;
