/**
 * 积分 接口的统一出口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const api = "/api/";

const index = {
  // 商品列表
  list(params) {
    return axios.get(api + "goods/list", { params: params });
  },
  //   商品详情
  detail(params) {
    return axios.post(api + "goods/detail", qs.stringify(params));
  },
  //   下单
  create(params) {
    return axios.post(api + "order/create", qs.stringify(params));
  },
  //   订单加地址
  address(params) {
    return axios.post(api + "order/address", qs.stringify(params));
  },
  //   订单列表
  orderList(params) {
    return axios.get(api + "order/list", { params: params });
  },
  //   订单详情
  orderDetail(params) {
    return axios.post(api + "order/detail", qs.stringify(params));
  },
};

// 导出接口
export default index;
