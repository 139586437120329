<template>
  <div class="page-index">
    <div class="content" v-if="detail">
      <div class="title">{{detail.title}}</div>
      <div class="banner-pic" v-for="(item,index) in detail.pic" :key="index">
        <img :src="adddom(item)" />
      </div>
      <div class="banner-pic" v-for="(item,index) in detail.video" :key="index">
        <video
          class="item-video"
          :src="adddom(item)"
          enable-danmu
          :show-fullscreen-btn="false"
          :show-center-play-btn="true"
          :show-play-btn="true"
          controls
        ></video>
      </div>
      <div class="banner-content" v-if="detail.content">
        <div v-html="detail.content"></div>
      </div>
    </div>
    <!-- 加载 -->
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadShow:false,
      bannerId: null,
      detail: {} //详情
    };
  },
  created() {
    this.bannerId = this.$route.query.id;
    this.getInfo();
  },
  methods: {
    // 图片拼接前缀
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getInfo() {
      let that = this;
      that.loadShow = true;
      that.$api.index.single_page({ id: that.bannerId }).then(res => {
        if (res.code == 200) {
          that.loadShow = false;
          that.detail = res.result.detail;
        }else{
           that.loadShow = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.page-index {
  padding: 5px 0 15px;
}
.page-index .title {
  text-align: center;
  margin-bottom: 15px;
}
.page-index .banner-pic {
  padding: 0 15px;
  border-radius: 10px;
  overflow: hidden;
  height: 154px;
  margin-bottom: 10px;
}
.page-index .banner-pic img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.page-index .banner-content {
  padding: 0 15px;
}
.item-video {
  width: 345px;
  height: 154px;
  border-radius: 10px;
  object-fit: contain;
}
</style>