<template>
    <div class="layout">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component v-if="$route.meta.keepAlive" :is="Component" :key="$route.name" />
          </keep-alive>
          <component v-if="!$route.meta.keepAlive" :is="Component" :key="$route.name" />
        </router-view>
        <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </div>
</template>
<script>
// import router from '../.././router'
// import {
//   LOGOUT,
//   LOGIN
// } from '../.././vuex/mutation_types'
export default {
  data () {
    return {
      isCollapsed: false, // 侧栏开关
      menuData: [], // 左侧菜单
      breadCrumbList: [], // 面包屑
      title: '首页', // 标题
      btns: [], // 功能按钮组
      active: 'home',
      shareConfig: {},
    }
  },
  computed: {
  },
  watch:{
      $route(to, from) {
          // 根据路由判断, meta.share_config 为true, 统一设置分享
          if (this.$route.meta.share_config) {
              this.shareFun();
          }
      }
  },
  created: function () {
    if (this.$route.name !== this.active) {
      this.active = this.$route.name
    }
  },
  mounted() {
  },
  methods: {
    pageChange (event) {
      this.$router.push(`/${event}/index`)
      this.active = event
    },
    shareFun() {
        var that = this;
        var tempurl = location.href;
        const url = location.href.split("#")[0];
        console.log("Iframe ---share----url=" + url);
        const shareUrl = location.href.split("#")[0];
        that.$api.index.getShare({url: tempurl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // console.log('Iframe ---微信分享设置---开始');
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        title: that.$publicData.shareTitle, // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        title: that.$publicData.shareTitle, // 分享标题
                        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                    // console.log('Iframe ---微信分享设置---结束');
                });
            }
        });
    },
  }
}
</script>
