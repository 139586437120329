<template>
  <div class="index">
    <div class="top">
      <div class="search">
        <input v-model="searchText" type="text" placeholder="搜索任务" />
        <img @click="serachHandel" class="btn" src="../../public/statics/images/index/search.png" />
      </div>
      <div id="main"></div>
      <h1
        style="font-size: 0.24rem;font-weight: 400;color: #FFFFFF;text-align:center;margin: 0.15rem 0;"
      >
        当前
        <span style="font-size: 0.4rem;color: #5ECCFF;">{{onlineNum}}</span> 个发声人在线
      </h1>
      <div class="screen" style="height:38px"></div>
      <div style="display:flex;padding:0rem 0.32rem 0rem 0.4rem;align-items: center;background: rgba(71,132,254,0.2);">
         <!-- <van-icon color="#fff" size="18px" name="volume-o" /> -->
        <h1
          style="width:100%;font-size: 0.4rem;font-weight: 400;color: #FFFFFF;text-align:center;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin-left:0.2rem;padding:0.13rem;"
          @click="toPath('/taskDetail',{id:dutyData.id})"
        ><img style="width:0.4rem;height:0.45rem;vertical-align: middle;" src="../../public/statics/images/fire.png"> <span style="color:#ff7e32;">人气热文：</span>{{dutyData.title || ''}}</h1>
      </div>
      <div style="height:0.6rem;"></div>
    </div>
    <!-- <div class="but" style="z-index:1;" data-state="0"></div> -->
    <div id="cont">
      <div class="but" :class="state == 1 ? 'xia' : ''" data-state="0"></div>
      <div class="taskHead">
        <div>
          <img src="../../public/statics/images/index/task.png" />
          任务列表
        </div>
        <div class="more">
          更多
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- <van-pull-refresh style="flex: 1;overflow: auto;" v-model="refreshing" @refresh="onRefresh"> -->
        <div class="taskList" style="padding:0;height:100%;">
          <van-list
            v-model:loading="loading"
            class="article1"
            :finished="finished"
            :finished-text="dutyList.length == 0 ? '' : '没有更多了'"
            @load="getList()"
          >
            <div
              class="taskItem"
              v-for="(item,idx) in dutyList"
              :key="idx"
              @click="toTaskDetail(item)"
            >
              <div>
                <img
                  style="height:1.87rem;width:2.88rem;object-fit: cover;background:#f8f8f8;"
                  :src="adddom(item.thumb)"
                />
              </div>
              <div>
                <h1>{{item.title}}</h1>
                <!-- <h2>领取任务人数：{{item.ling_num}}人</h2> -->
                <p
                  style="width:100%;justify-content: space-between;margin-top: 0.1rem;position: absolute;left: 0;bottom: 0;"
                >
                  <!-- <img src="../../public/statics/images/index/red.png" /> -->
                  {{timestampToTime(item.created_at, 4)}}
                  <!-- <em>{{item.ling_num > 10000 ? (item.ling_num/10000).toFixed(1)+' 万': item.ling_num}}人转发</em> -->
                  <!-- <span style="display: inline-block;line-height: 0.5rem;background: #F6F6F6;font-size: 0.25rem;color: rgba(0, 0, 0, 0.6);padding: 0 0.1rem;">{{item.hit > 1000 ? (item.hit/1000).toFixed(2) + ' k' : item.hit}} 人浏览</span> -->
                  <span style>{{item.hit > 10000 ? (item.hit/10000).toFixed(1)+' 万': item.hit}} 人浏览</span>
                </p>
              </div>
            </div>
          </van-list>
          <van-empty v-if="!loading && dutyList.length == 0" description="暂无数据" />
        </div>
      <!-- </van-pull-refresh> -->
    </div>
    <index-footer></index-footer>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import indexFooter from "./footer.vue";
import * as echarts from "echarts";
import utils from "@/api/Utils";

const getRandom = (min, max) => parseInt(Math.random() * (max - min + 1)) + min;
export default {
  name: "Home",
  data() {
    return {
      onlineNum: "",
      dutyData: "",
      state: "",
      timer: "", // 10秒调用一次接口
      shareConfig: {},
      loadNum: 0,
      loadShow: false,
      screen: null,
      danmuList: [],
      handler: null,
      myChart: "",
      searchText: "",
      pages: {
        page: 1,
        size: 10
      },
      dutyList: [],
      refreshing: false, //下拉刷新
      loading: false,
      finished: false,
      getCodeUrl: "http://speaker.shanmuzhi.com/api/wxcode",
      memberUrl: "http://speaker.shanmuzhi.com/#/",
      star: require("../../public/statics/images/star.png")
    };
  },
  watch: {},
  components: {
    indexFooter
  },
  created() {
    this.getCodeUrl = this.$publicData.domain + "api/wxcode";
    this.memberUrl = this.$publicData.domain + "#/";
  },
  methods: {
    timestampToTime(timestamp, type) {
      if (!timestamp) return;
      return utils.timestampToTime(timestamp, type);
    },
    serachHandel() {
      console.log(this.searchText);
      let that = this;
      that.dutyList = [];
      that.loading = true;
      that.finished = false;
      that.pages.key = this.searchText;
      that.pages.page = 1;
      that.getList();
    },
    // 截取url中的code方法
    getUrlCode() {
      var allUrl = location.href;
      var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    onRefresh() {
      let that = this;
      that.refreshing = false;
      that.dutyList = [];
      that.loading = true;
      that.finished = false;
      that.pages.page = 1;
      that.getList();
    },
    getList() {
      var token = Cookies.get("spToken");
      if (!token) {
        return;
      }
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        key: that.pages.key,
        page: that.pages.page,
        size: that.pages.size
      };
      that.$api.index.dutyList(params).then(function(res) {
        if (res.code == 200) {
          that.dutyList = that.dutyList.concat(res.result);
          that.pages.page++;
          // 加载状态结束
          that.loading = false;
          if (res.result.length < 1) {
            that.finished = true;
          }
        }
      });
    },
    toTaskDetail(item) {
      console.log(item);
      this.toPath("/taskDetail", { id: item.id });
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    },
    ChartInit() {
      let chartDom = document.getElementById("main");
      this.myChart = echarts.init(chartDom);
      this.chartUpdata();
    },
    chartUpdata() {
      let that = this;
      this.myChart.showLoading();
      this.$api.index
        .dutyMap({ token: Cookies.get("spToken") })
        .then(function(res) {
          if (res.code == 200) {
            // console.log(res)
            let graph = res.result;
            that.onlineNum = res.result.online_num;
            that.dutyData = res.result.duty;
            that.myChart.hideLoading();
            graph.nodes.forEach(function(node) {
              let name = node.name.replace(/\s*/g, "");
              node.name = name.length > 10 ? name.slice(0, 10) + "..." : name;
              node.symbolSize = (node.symbolSize * 2) / 3;
              if(node.id == 0){
                node.symbolSize = node.symbolSize + 10
                node.symbol='image://'+that.star
              }
            });
            let option = {
              title: {},
              color: [
                {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#376FFF" // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#62A5FF" // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              ],
              tooltip: {
                trigger: "item",
                axisPointer: {
                  type: "shadow"
                },
                textStyle: {
                  fontSize: 14,
                  color: "#fff"
                },
                // position: ['50%', '50%'], // 弹层定位
                // 自定义弹窗样式
                padding: 0,
                backgroundColor: "transparent",
                formatter: function(params) {
                  let name = params.name.replace(/\s*/g, "");
                  let arr = params.name.split(">");
                  console.log(params);
                  // console.log(arr)
                  // console.log(params.data.source)
                  // console.log(params.data.target)
                  if (
                    name.indexOf(">") != -1 &&
                    Number(arr[0]) == params.data.source &&
                    Number(arr[1]) == params.data.target
                  ) {
                    return "";
                  }
                  let oldstr = `<div style = " padding: 5px 10px;
                                        background: -webkit-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: -o-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: -moz-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: radial-gradient(circle,rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        no-repeat center center ;
                                        width:100%;height:100%; ">${
                                          name.length > 10
                                            ? name.slice(0, 10) + "..."
                                            : name
                                        }</div>`; // 设置显示内容
                  let str = `<div style = " padding: 5px 10px;
                                        background: -webkit-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: -o-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: -moz-radial-gradient(circle, rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        background: radial-gradient(circle,rgba(0,86,165,0.70),rgba(0,86,165,0.5)) !important;
                                        no-repeat center center ;
                                        width:100%;height:100%; ">阅读贡献量 ：${params.value}</div>`; // 设置显示内容
                  return str;
                }
              },
              legend: [
                {
                  // selectedMode: 'single',
                  data: graph.categories.map(function(a) {
                    // return a.name;
                  })
                }
              ],
              series: [
                {
                  name: "",
                  type: "graph",
                  layout: "force",
                  data: graph.nodes,
                  links: graph.links,
                  categories: graph.categories,
                  roam: true, // 拖动
                  edgeLabel: {
                    show: false
                  },
                  label: {
                    position: "bottom",
                    color: "#D4ECFF", // 每个节点下的文字显示
                    // color: "rgba(0,0,0,0)", // 每个节点下的文字显示
                    formatter: "{b}",
                    show: true // 每个点的文字显示
                  },
                  force: {
                    repulsion: $(window).width() * 50 // 控制图表大小
                    // edgeLength: 500,
                    // layoutAnimation: true
                  },
                  lineStyle: {
                    color: "#5383f1",
                    width: 1,
                    opacity: 0.5
                  }
                }
              ]
            };
            that.myChart.setOption(option);
            that.myChart.on("click", function(params) {
              // console.log(params.name);
            });
            setTimeout(function() {
              $("#cont").css({
                top: $(".index .top").height() - 15 + "px"
              });
            }, 300);
          }
        });
    },
    screenInit() {
      this.screen = new BulletJs(".screen", {
        trackHeight: 35,
        // speed: 1000,
        pauseOnClick: false,
        pauseOnHover: false,
        duration: "3s"
      });
      // this.sendDanmu();
    },
    sendDanmu() {
      this.sendAction();
    },
    sendAction() {
      let that = this;
      this.handler = setInterval(() => {
        if (that.danmuList.length < 1) return;
        const index = getRandom(0, that.danmuList.length - 1);
        const str = that.danmuList[index].title.slice(0, 7) + "...";
        const src = that.adddom(that.danmuList[index].speaker.thumb);
        const name = that.danmuList[index].speaker.name;
        const dom = `<img class="screenavator" src="${src}" /><span class="screentext">${name +
          str}</span>`;
        // that.danmuList.splice(index,1)
        if (!that.screen) return;
        that.screen.push(dom);
      }, 1000);
    },
    shareFun() {
      var that = this;
      var tempurl = location.href;
      const url = location.href.split("#")[0];
      console.log("Iframe ---share----url=" + url);
      const shareUrl = location.href.split("#")[0];
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          // console.log('Iframe ---微信分享设置---开始');
          // 分享
          wx.ready(function() {
            var shareDataA = {
              title: that.$publicData.shareTitle, // 分享标题
              desc: that.$publicData.shareDesc, // 分享描述
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };

            var shareDataB = {
              title: that.$publicData.shareTitle, // 分享标题
              link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: that.$publicData.shareImg, // 分享图标
              success: function() {},
              cancel: function(e) {}
            };
            wx.updateAppMessageShareData(shareDataA); // 分享给朋友
            wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
            // console.log('Iframe ---微信分享设置---结束');
          });
        }
      });
    },
    speakerLog() {
      var token = Cookies.get("spToken");
      if (!token) {
        return;
      }
      var that = this;
      var params = {
        token: Cookies.get("spToken")
      };
      that.$api.index.speakerLog(params).then(function(res) {
        if (res.code == 200) {
          that.loadNum--;
          if (that.loadNum == 0) {
            that.loadShow = false;
          }
          // console.log(res.result)
          that.danmuList = res.result;
          that.sendDanmu();
        }
      });
    },
    getLocation() {
      var that = this;
      var tempurl = location.href;
      that.$api.index.getShare({ url: tempurl }).then(res => {
        if (res.code == 200) {
          that.shareConfig = res.result;
          wx.config({
            debug: that.shareConfig.debug,
            beta: that.shareConfig.beta,
            jsApiList: that.shareConfig.jsApiList,
            appId: that.shareConfig.appId,
            nonceStr: that.shareConfig.nonceStr, // 随机串
            timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
            signature: that.shareConfig.signature // 签名
          });
          console.log('微信设置完成')
          wx.ready(function() {
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // var speed = res.speed; // 速度，以米/每秒计
                // var accuracy = res.accuracy; // 位置精度
                // console.log(res)
                console.log('获取成功')
                let locationData = {
                  token: Cookies.get("spToken"),
                  lat: latitude,
                  lng: longitude
                }
                that.$api.index.speakerEditArea(locationData).then(res => {
                  if (res.code == 200) {
                    // 更改
                  }
                })
              }
            })
          })
        }
      })
    }
  },
  activated() {
  },
  mounted() {
    let that = this;
    this.shareFun();
    // 点击更多，任务列表最大化显示
    $(".index #cont .taskHead .more")
      .unbind()
      .on("click", function() {
        $(".but").data("state", 1);
        that.state = 1;
        $("#cont").css({
          top: $(".index .top .search").outerHeight(true) + 15 + "px"
        });
        $("#main").css({ opacity: 0 });
      });
    // 点击最大化，在点击最小化
    $(".but")
      .unbind()
      .on("click", function() {
        if ($(this).data("state") == 0) {
          $("#cont").css({
            top: $(".index .top .search").outerHeight(true) + 15 + "px"
          });
          $(this).data("state", 1);
          that.state = 1;
          $("#main").css({ opacity: 0 });
        } else {
          $("#cont").css({
            top: $(".index .top").height() - 15 + "px"
          });
          $(this).data("state", 0);
          that.state = 0;
          $("#main").css({ opacity: 1 });
        }
      });
    $("#cont").css({
      top: $(".index .top").height() + "px"
    });
    setTimeout(function() {
      $("#cont").css({
        top: $(".index .top").height() - 15 + "px"
      });
    }, 300);
    setTimeout(function() {
      $("#cont").css({
        top: $(".index .top").height() - 15 + "px"
      });
    }, 1000);
    let priv = [
      {
        created_at: null,
        id: 34,
        right: {
          created_at: "2022-02-15 16:02:56",
          id: 1,
          name: "我的任务",
          sort: 0,
          status: 1,
          updated_at: "2022-03-03 09:09:36"
        },
        right_id: 1,
        show_role_id: 2,
        updated_at: null
      },
      {
        created_at: null,
        id: 34,
        right: {
          created_at: "2022-02-15 16:02:56",
          id: 2,
          name: "我的发布",
          sort: 0,
          status: 1,
          updated_at: "2022-03-03 09:09:36"
        },
        right_id: 1,
        show_role_id: 2,
        updated_at: null
      },
      {
        created_at: null,
        id: 34,
        right: {
          created_at: "2022-02-15 16:02:56",
          id: 3,
          name: "区域排行",
          sort: 0,
          status: 1,
          updated_at: "2022-03-03 09:09:36"
        },
        right_id: 1,
        show_role_id: 2,
        updated_at: null
      }
    ];
    // localStorage.setItem('priv', JSON.stringify(priv))
		if (location.origin.indexOf('localhost') != -1) {
			Cookies.set("spToken", "ceshitoken", { expires: 365 });//祖美乐
		}
    // Cookies.set("spToken", "b9fb17c9c8721bda9b519e0f0fa65d5f", { expires: 365 });//杜亚龙
    // Cookies.set("spToken", "1bb73f88cdc240e0a9a59812c7d97904", { expires: 365 });
    // Cookies.set("spToken", "09d2afcefd32f2af1c52af5f97d2a045", { expires: 365 });
    // Cookies.set("spToken", "4f215d504078e4ee1c5513ff26471785", { expires: 365 }); //拥抱太阳
    // Cookies.set('id', '5', { expires: 365 })
    // this.getList();
    var token = Cookies.get("spToken");
    if (token && token != undefined) {
      // 有token 直接展示
      // this.getList();
      that.ChartInit();
      that.screenInit();
      that.loadNum++;
      that.loadShow = true;
      that.speakerLog();
      that.timer = setInterval(() => {
        that.speakerLog();
      }, 30000);
      return;
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
      var code = codeArr["code"];
      var params = {
        code: code
      };
      // 绑定
      this.$api.index.wxlogin(params).then(res => {
        if (res.code == 200) {
          localStorage.setItem("priv", JSON.stringify(res.result.priv));
          Cookies.set("spToken", res.result.token, { expires: 365 });
          Cookies.set("id", res.result.id, { expires: 365 });
          if (res.result.location_status == 0) {
            console.log('调用获取地理位置方法')
            console.log(this)
            console.log(that)
            that.getLocation();
          }
          this.getList();
          that.ChartInit();
          that.screenInit();
          that.loadNum++;
          that.loadShow = true;
          that.speakerLog();
          that.timer = setInterval(() => {
            that.speakerLog();
          }, 30000);
          window.history.replaceState(null, null, this.memberUrl);
        } else {
          location.href=that.$publicData.domain
        }
      });
    } else {
      window.location.href = this.getCodeUrl;
    }
  },
  beforeUnmount() {
    if (this.myChart) {
      this.myChart.clear()
      this.myChart.dispose()
      this.myChart = null
      window.removeEventListener('resize', () => { this.myChart.resize() })
    }
    this.screen && this.screen.pause();
    this.handler && clearInterval(this.handler);
    this.timer && clearInterval(this.timer);
    $(".bullet-temp-container").remove();
    if (this.screen) {
      this.screen = "";
    }
  }
};
</script>

<style scoped>
.index .but.xia {
  transform: rotate(180deg);
}
</style>
<style>
/*滚动条 start*/
.van-pull-refresh::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background-color: #f5f5f5;
}
/*定义滚动条轨道 内阴影+圆角*/
.van-pull-refresh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}
/*定义滑块 内阴影+圆角*/
.van-pull-refresh::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(158, 158, 158, 0.7);
}
.van-pull-refresh::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(158, 158, 158, 1);
}
</style>
