import { setInterval, clearInterval } from 'timers'
const utils = {
  timestampToTime: function (timestamp, type) {
    if (timestamp === null || timestamp === '') {
      return ''
    }
    timestamp = timestamp.replace(/-/g, '/')
    // 时间戳转时间
    let date = new Date(timestamp)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-'
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
    let h = date.getHours() + ':'
    let m = date.getMinutes() + ':'
    let s = date.getSeconds()
    if (type === 1) {
      return Y + M + D
    }
    if (type === 2) {
      return h + m + s
    }
    if (type === 3) {
      return M + D + ('0' + h).slice(-3) + ('0' + date.getMinutes()).slice(-2)
    }
    if (type === 4) {
      return M + D
    }
    return Y + M + D + h + m + s
  },
  /*
  * 判断是不是微信环境 boolean
  */
  isWeiXin: function () {
    var ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') !== -1) {
      return true
    } else {
      return false
    }
  },
  osType: function (needle) {
    var _userAgent = window.navigator.userAgent.toLowerCase()
    return _userAgent.indexOf(needle) !== -1
  },
  /*
  * 判断是不是ios系统。boolean
  */
  ios: function () {
    return this.iphone() || this.ipod() || this.ipad()
  },
  iphone: function () {
    return this.osType('iphone')
  },
  ipod: function () {
    return this.osType('ipod')
  },
  ipad: function () {
    return this.osType('ipad')
  },
  /*
  * 判断是不是安卓系统 boolean
  */
  android: function () {
    return this.osType('android')
  },
  androidPhone: function () {
    return this.android() && this.osType('mobile')
  },
  androidTablet: function () {
    return this.android() && !this.osType('mobile')
  },
  /*
  * 验证是不是手机号。1开头的11位数字。
  */
  checkPhone: function (a) {
    var patrn = /^((?:11|12|13|14|15|16|17|18|19)\d{9}|0(?:10|2\d|[3-9]\d{2})[1-9]\d{6,7})$/
    if (!patrn.exec(a)) return false
    return true
  },
  /*
  * 加载外部js css， url是绝对地址 filetype是文件类型 callbackFuc 是下载完的回调函数
  */
  addFile (url, fileType, callbackFuc) {
    let callback = callbackFuc || function () { console.log(url, '加载完毕') }
    var date = new Date()
    var min = date.getMinutes() % 10 === 0 ? date.getMinutes() : Math.floor(date.getMinutes() / 10) * 10
    var timestamp = '?t=' + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + min
    var head = document.getElementsByTagName('HEAD').item(0)
    var link
    if (fileType === 'js') {
      link = document.createElement('script')
      link.type = 'text/javascript'
      link.src = url + timestamp
    } else {
      link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = url + timestamp
    }
    link.onload = link.onreadystatechange = function () {
      if (link.ready) {
        return false
      }
      if (!link.readyState || link.readyState === 'loaded' || link.readyState === 'complete') {
        link.ready = true
        callback()
      }
    }
    head.appendChild(link)
  },
  /*
  * 字符串str里是否包含conStr。
  */
  contain (str, conStr) {
    return str.indexOf(conStr) !== -1
  },
  /**
   *alert提示，vm是组件的this，title 标题， text 提示内容
   */
  alertPlug: function (vm, title, text) {
    if (window.swal) {
      window.swal({
        title: title,
        text: text,
        confirmButtonText: '确定',
        confirmButtonColor: 'rgba(255, 132, 0, 1)'
      })
    } else {
      vm.$vux.alert.show({
        title: title,
        content: text,
        onShow () {
        },
        onHide () {
        }
      })
    }
  },
  /**
   *校验表单
   */
  validForm: function (vm, formName, callFuc) {
    vm.$refs[formName].validate((valid) => {
      if (valid) {
        callFuc(vm)
      } else {
        return false
      }
    })
  },
  /*
     * 获取Token
     */
  getToken: function () {
    var tokenValue = this.getCookie('myCarWebtoken')
    return tokenValue
  },
  /*
   * 设置token
   */
  setToken: function (tokenValue) {
    utils.setCookie('myCarWebtoken', tokenValue, 1)
  },
  /*
    * 获取cookie
    */
  getCookie: function (name) {
    var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
    return v ? v[2] : null
  },

  /*
   * 设置cookie
   */
  setCookie: function (name, value, days) {
    var d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
    window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
  },
  /*
   * 删除cookie
   */
  deleteCookie: function (name) {
    utils.setCookie(name, '', -1)
  },
  countDown: function (vm) {
    var self = vm
    self.timerStatu = true
    self.timer = setInterval(function () {
      if (self.countDownNumber >= 2) {
        self.countDownNumber = self.countDownNumber - 1
        self.countDownText = self.countDownNumber + 's'
      } else {
        self.countDownText = '获取验证码'
        self.timerStatu = false
        clearInterval(self.timer)
      }
    }, 1000)
  },
  secondToTime (secondData) {
    let second = secondData % 60
    second = second > 10 ? second : '0' + second
    let min = (secondData - second) / 60 % 60
    min = min > 10 ? min : '0' + min
    let hour = ((secondData - second) / 60 - min) / 60
    hour = hour > 10 ? hour : '0' + hour
    return [hour, min, second]
  },
  errorMessage (vm, msg) {
    vm.$vux.alert.show({
      title: '',
      content: msg
    })
  },
  /*
  * 加载外部js， options是一个对象，options。url是地址，options。callback 是加载完后的回调函数。
  */
  loadJs (options) {
    var nodeHead = document.getElementsByTagName('head')[0]
    var nodeScript = null
    nodeScript = document.createElement('script')
    nodeScript.setAttribute('type', 'text/javascript')
    nodeScript.setAttribute('src', options.url)
    if (options.callback != null) {
      nodeScript.onload = nodeScript.onreadystatechange = function () {
        if (nodeScript.ready) {
          return false
        }
        if (!nodeScript.readyState || nodeScript.readyState === 'loaded' || nodeScript.readyState === 'complete') {
          nodeScript.ready = true
          options.callback()
        }
      }
    }
    nodeHead.appendChild(nodeScript)
  },
  /*
  * js检测当前url是否已加载，返回booble值
  */
  checkIncludeJS (url) {
    var scriptNode = document.getElementsByTagName('script')
    for (var i = 0; i < scriptNode.length; i++) {
      if (scriptNode[i].src.indexOf(url) >= 0) {
        return true
      }
    }
    return false
  },
  /*
  * css检测url是否已加载，返回booble值
  */
  checkIncludeCss (url) {
    var scriptNode = document.getElementsByTagName('link')
    for (var i = 0; i < scriptNode.length; i++) {
      if (scriptNode[i].href.indexOf(url) >= 0) {
        return true
      }
    }
    return false
  },
  /*
  * 加载vconsole
  */
  initConsole () {
    if (utils.contain(window.location.href, 'test') || utils.contain(window.location.href, 'pre') || utils.contain(window.location.href, 'dev')) {
      utils.addFile('./static/js/vconsole.min.js', 'js',
        function () {
          window.vConsole = new window.VConsole()
        }
      ) // 加载vconsole
    } else {
      window.console.log = function () {}
    }
  }
}
export default utils
