import {
  LOGIN,
  LOGOUT,
  WXLOGIN
} from '.././mutation_types'

// localStorage 保存数据时间更久，不会因为关闭标签而丢失

const state = {
  user_id: localStorage.getItem('user_id') || 0,
  role: localStorage.getItem('role') || 0,
  user_name: localStorage.getItem('user_name') || '未登录',
  token: localStorage.getItem('token') || ''
}

const getters = {
  user_id: state => {
    return state.user_id
  },
  role: state => {
    return state.role
  },
  user_name: state => {
    return state.user_name
  },
  token: state => {
    return state.token
  }
}

const mutations = {
  [WXLOGIN] (state, data) {
    localStorage.setItem('openId', data.openId) // 设置localStorage
    state.openId = data.openId
  },
  [LOGIN] (state, data) {
    localStorage.setItem('user_id', data.id) // 设置localStorage
    localStorage.setItem('role', data.role)
    localStorage.setItem('user_name', data.name)
    localStorage.setItem('token', data.token)
    state.user_id = data.id
    state.role = data.role
    state.user_name = data.name
    state.token = data.token
  },
  [LOGOUT] (state) {
    localStorage.removeItem('user_id') // 移除localStorage
    localStorage.removeItem('role')
    localStorage.removeItem('user_name')
    localStorage.removeItem('token')
    localStorage.removeItem('code')
    localStorage.removeItem('phone')
    localStorage.removeItem('openid')
    localStorage.removeItem('unionid')
    state.user_id = 0
    state.role = 0
    state.user_name = ''
    state.token = ''
  }
}

export default {
  state,
  mutations,
  getters
}
