<template>
  <ul class="footer">
    <li @click="toPath('/',{})">
      <div :class="[$route.meta.active == '1' ? 'active' : '']">
        <img src="../../public/statics/images/index/home.png">
      </div>
      首页
    </li>
    <!-- <li @click="toPath('/article',{})"> -->
    <!-- <li @click="toPath('/renwuList',{})">
      <div :class="[$route.meta.active == '3' ? 'active' : '']">
        <img src="../../public/statics/images/index/article.png">
      </div>
      任务
    </li> -->
    <li @click="toPath('/worldlList',{})">
      <div :class="[$route.meta.active == '3' ? 'active' : '']">
        <img src="../../public/statics/images/index/article.png">
      </div>
      视界
    </li>
    <li @click="toPath('/special',{})">
      <div :class="[$route.meta.active == '4' ? 'active' : '']">
        <img style="height:0.33rem;width:0.27rem;" src="../../public/statics/images/index/special.png">
      </div>
      专题
    </li>
    <li @click="toPath('/rank',{})">
      <div :class="[$route.meta.active == '2' ? 'active' : '']">
        <img style="width:0.33rem;height:0.27rem;" src="../../public/statics/images/index/taskicon.png">
      </div>
      排行
    </li>
    <li @click="toMy">
      <div :class="[$route.meta.active == '5' ? 'active' : '']">
        <img src="../../public/statics/images/index/my.png">
      </div>
      我的
    </li>
  </ul>
</template>

<script>
import Cookies from "js-cookie";
import { Dialog } from 'vant';
export default {
  data() {
    return {
      active: ''
    }
  },
  created () {
	},
  mounted() {
  },
  activated() {
  },
  methods:{
    memberInfo() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.index.memberInfo(ps).then(function(response) {
        if (response.code === 200) {
          localStorage.setItem('priv', JSON.stringify(response.result.priv))
          if (response.result.speaker_id == 0 && response.result.speaker == null) {
            that.toPath('/apply',{})
          } else if(response.result.speaker && response.result.speaker.status == 0) {
            // 已提交申请
            Dialog.confirm({
              title: '',
              message: '您已提交申请，请耐心等待。',
              confirmButtonColor: '#0b47ac'
            })
            .then(() => {
              // on confirm
              console.log('确定')
            })
            .catch(() => {
              // on cancel
              console.log('cancel')
            });
          } else if (response.result.speaker && response.result.speaker.status == -1) {
            // 驳回，remark原因
            Dialog.confirm({
              title: '',
              message: '审核未通过,原因：' + response.result.speaker.remark,
              confirmButtonColor: '#0b47ac'
            })
            .then(() => {
              // on confirm
              console.log('确定，跳转到申请页面')
              that.toPath('/apply',{})
            })
            .catch(() => {
              // on cancel
              console.log('cancel')
            });
          } else if (response.result.speaker && response.result.speaker.status == 1) {
            // 审核通过
            that.toPath('/my',{})
          }
        }
      })
    },
    toMy() {
      // 根据登陆返回变量来判断角色，看到不同内容。
      // this.toPath('/my',{})
      // this.toPath('/apply',{})
      this.memberInfo()
    },
    toPath (url, obj) {
      if (this.$route.fullPath == url) {
        return
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        })
        window.location.reload()
        return
      }
      this.$router.push({
        path: url,
        query: obj
      })
    }
  }
};
</script>

<style>
.footer{
  padding: 0 0.4rem;
  padding-top: 0.37rem;
  width: 100%;
  height: 2.3rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(72, 128, 255, 0.2);
  display: flex;
  justify-content: space-between;
  z-index: 100;
}
.footer li{
  flex: 1;
  text-align: center;
}
.footer li div{
  width: 0.56rem;
  height: 0.56rem;
  background: rgba(11, 71, 172, 0.4);
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer li div.active{
  background: rgba(11, 71, 172, 1);
}
.footer li div img{
  width: 0.27rem;
  height: 0.27rem;
}
</style>