/**
 * 首页 接口的统一出口
 */
import axios from '../http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块，把请求字段直接映射过来

const api = '/api/'

const index = {
  // 获取 个人中心-文章
  articleList (params) {
    return axios.get(api + 'speaker/articleList', { params: params })
  },
  // 申请成为发声人
  apply (params) {
    return axios.post(api + 'speaker/apply', qs.stringify(params))
  },
  // 发声人信息
  detail (params) {
    return axios.post(api + 'speaker/detail', qs.stringify(params))
  },
  //积分日志
  scoreLog (params) {
    return axios.get(api + 'speaker/scoreLog', { params: params })
  },
  //角色变化日志
  roleChangeLog (params) {
    return axios.get(api + 'speaker/roleChangeLog', { params: params })
  },
  //获取基本信息
  speakerBaseInfo (params) {
    return axios.get(api + 'speakerBaseInfo', { params: params })
  },
  //我的任务浮标
  dutyMy (params) {
    return axios.get(api + 'duty/my', { params: params })
  },
  // 图片上传
  uploadBase64 (params) {
    return axios.post(api + 'common/uploadBase64', qs.stringify(params))
  },
  // 改头像
  editThumb (params) {
    return axios.post(api + 'speaker/editThumb', qs.stringify(params))
  },
}

// 导出接口
export default index
