<template>
  <div class="articleDetail">
    <!-- 底部导航 -->
    <div class="artHead">
      <h1 class="title">{{viewData.title}}</h1>
      <div class="flexBox">
        <div class="boxLeft">
          <img :src="adddom(viewData.speaker ? viewData.speaker.thumb : '')">
          <div>
            <h2>{{viewData.speaker ? viewData.speaker.name : ''}}</h2>
            <p>提交时间：{{viewData.created_at}}</p>
          </div>
        </div>
        <div class="boxRight">浏览量:{{viewData.hit}}人</div>
      </div>
    </div>
    <!-- <img class="pic" :src="adddom(viewData.thumb)"> -->
    <div class="cont" v-html="viewData.content"></div>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
    <div style="height:3rem;"></div>
    <index-footer></index-footer>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import indexFooter from "../views/footer.vue";
export default {
  data() {
    return {
      loadNum: 0,
      loadShow: false,
      viewData: '',
      getCodeUrl:"http://speaker.shanmuzhi.com/api/wxcode?url=articleShare",
      memberUrl: "http://speaker.shanmuzhi.com/#/",
      shareUrl: require("../../public/statics/images/share.jpg"),
    };
  },
  components: {
    indexFooter
  },
  activated() {
  },
  mounted(){
  },
  created() {
    this.getCodeUrl = this.$publicData.domain + 'api/wxcode?url=articleShare'
    this.memberUrl = this.$publicData.domain + '#/'
    if(this.$route.query.id !=undefined){
      Cookies.set('shareId', this.$route.query.id)
      Cookies.set('speakerId', this.$route.query.share_speaker_id)
    }
    let that = this
    var token = Cookies.get('spToken');
    if (token && token != undefined) {
      // 有token 直接展示
      that.loadNum++
      that.loadShow = true
      that.getDetail()
      return;
    }
    // 判断是否已注册，如果注册则正常微信登录，如果没有则先注册。
    var codeArr = this.getUrlCode();
    // 获取code
    if (codeArr["code"] != undefined) {
        var code = codeArr["code"];
        var params = {
            code: code
        };
        // 绑定
        this.$api.index.wxlogin(params).then(res => {
            if (res.code == 200) {
                Cookies.set('spToken', res.result.token, { expires: 365 })
                localStorage.setItem('priv', JSON.stringify(res.result.priv))
                that.loadNum++
                that.loadShow = true
                that.getDetail()
                // window.history.replaceState( null, null, that.memberUrl );
            }
        });
    }else{
      window.location.href = this.getCodeUrl;
    }
  },
  methods: {
    // 截取url中的code方法
    getUrlCode() {
        var allUrl = location.href;
        var url = allUrl.substring(allUrl.indexOf("?"), allUrl.length);
        this.winUrl = url;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
            }
        }
        return theRequest;
    },
    adddom (src) {
      if (src == '' || src == undefined || src == null) {
        console.log('src地址有问题')
        return ''
      }
      if (src.substr(0, 4).toLowerCase() === 'http') {
        return src
      }
      return this.$imgurl + src
    },
    getDetail() {
      let that = this
      let ps = {
        token: Cookies.get('spToken'),
        id: this.$route.query.id || Cookies.get('shareId'),
        share_speaker_id: this.$route.query.share_speaker_id || Cookies.get('speakerId')
      }
      that.$api.article.shareDetail(ps).then(res => {
        if (res.code == 200) {
          that.loadNum--
          if (that.loadNum==0){
            that.loadShow = false
          }
          // console.log(res.result)
          that.viewData = res.result
          window.document.title = that.viewData.title
          // 分享
          let routeData = that.$router.resolve({
            path: '/articleShare',
            query: {
              id: that.viewData.id,
              share_speaker_id: Cookies.get("id")
            }
          })
          let tempurl = location.href;
          let shareUrl = that.$publicData.domain + routeData.href
          console.log(shareUrl)
          that.$api.index.getShare({url:tempurl}).then(function(res){
            if (res.code == 200) {
              let shareConfig = res.result;
              console.log(shareConfig);
              wx.config({
                debug: shareConfig.debug,
                beta: shareConfig.beta,
                jsApiList: shareConfig.jsApiList,
                appId: shareConfig.appId,
                nonceStr: shareConfig.nonceStr, // 随机串
                timestamp: parseInt(shareConfig.timestamp), // 时间戳
                signature: shareConfig.signature // 签名
              });
              wx.ready(function () {
                var shareDataA = {
                  title: that.viewData.title, // 分享标题
                  desc: that.viewData.content, // 分享描述
                  link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.adddom(that.viewData.thumb) || that.$publicData.shareImg, // 分享图标
                  success: function () {
                  },
                  cancel: function (e) {
                  }
                }
                var shareDataB = {
                  title: that.viewData.title, // 分享标题
                  link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: that.adddom(that.viewData.thumb) || that.$publicData.shareImg, // 分享图标
                  success: function () {
                  },
                  cancel: function (e) {
                  }
                }
                setTimeout(function(){
                  wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                  wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                },2000)
              })
            }
          })
        }
      })
    },
    toBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>