<template>
<div id="app">
    <router-view></router-view>
</div>
</template>

<script>
// import router from '../.././router'
// import {
//   LOGOUT,
//   LOGIN
// } from '../.././vuex/mutation_types'
export default {
  name: 'App',
  data () {
    return {
      active_page: '', // 当前页面路径
      isLogin: false // 登录状态,
    }
  },
  mounted () {},
  created () {
    this.active_page = this.$route.path
  },
  watch: {
    '$route': function (to, from) {
      // console.log(to.path)
      if (window.localStorage.user_name !== undefined && window.localStorage.user_name !== null && window.localStorage.user_name !== '') {
        this.name = window.localStorage.user_name
        this.isLogin = true
      } else {
        this.name = ''
        this.isLogin = false
      }
      this.active_page = to.path
    }
  },
  methods: {
  }
}
</script>
