import { createStore } from 'vuex'
// 用户状态
import user from './modules/user'

export default createStore({
  // 所有要管理的module, 都要列在这里.
  modules: {
    user
  },
  middlewares: []
})
