/**
 * 首页 接口的统一出口
 */
import axios from "../http"; // 导入http中创建的axios实例
import qs from "qs"; // 根据需求是否导入qs模块，把请求字段直接映射过来

const api = "/api/";

const index = {
  // 获取调查问券
  questionnaireList(params) {
    return axios.get(api + "questionnaire/list", { params: params });
  },
  // post 调查问卷
  questionSubmit(params) {
    return axios.post(api + "questionnaire/submit", qs.stringify(params));
  },
};

// 导出接口
export default index;
