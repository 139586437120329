import { createRouter, createWebHashHistory } from 'vue-router'

import routers from './routers'


const router = createRouter({
  history: createWebHashHistory(),
  routes: routers
})

// 导航钩子，全局钩子
router.beforeEach((to, from, next) => {
  // 登录页面
  // console.log(from)
  if (to.meta.title) {
    window.document.title = to.meta.title
  }
  if (to.meta.title) {
    if (to.meta.title.indexOf('/') > 0) {
      if (Object.keys(to.query).length > 0) {
        window.document.title = to.meta.title.slice(3)
      } else {
        window.document.title = to.meta.title.slice(0, 2) + to.meta.title.slice(5)
      }
    } else {
      window.document.title = to.meta.title
    }
  }
  next()
})
router.afterEach(to => {
  $('html,body').scrollTop(0)
})

export default router
